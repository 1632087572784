export const deepEqualIgnoreUndefined = (obj1: Record<string, any>, obj2: Record<string, any>, uniqueSegments: any[] = []): boolean => {
  if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2
  }

  if (uniqueSegments.some((uniqueSegment) => uniqueSegment === obj1 || uniqueSegment === obj2)) return true
  uniqueSegments.push(obj1, obj2)

  const filteredObj1 = Object.fromEntries(Object.entries(obj1).filter(([_, v]) => v !== undefined))
  const filteredObj2 = Object.fromEntries(Object.entries(obj2).filter(([_, v]) => v !== undefined))

  const keys1 = Object.keys(filteredObj1)
  const keys2 = Object.keys(filteredObj2)

  if (keys1.length !== keys2.length) return false

  return keys1.every((key) => deepEqualIgnoreUndefined(filteredObj1[key], filteredObj2[key], uniqueSegments))
}
