import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InfoIcon from '@mui/icons-material/Info'
import { Grid, Typography } from '@mui/material'

import { LimitedFunctionalityBanner } from '../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import ShareUrlButton from '../../components/ShareUrlButton/ShareUrlButton'
import { useGameUpdateImpactsAccessCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentMarket } from '../../features/markets'
import { UpdateImpactsTableContainer } from '../../features/update-impacts'
import { UpdateImpactRowFilters } from '../../features/update-impacts/types/UpdateImpactRowFilters'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import pageService from '../../services/PageService'
import { revenueIsAvailableForMarket } from '../../services/RevenueService'
import { LockedFeatureId } from '../../types/LockedFeature'
import { VersionTag, VersionTagFeature } from '../../types/VersionTag'
import { convertVersionTags } from '../../utils/convertVersionTags'

export const UpdateImpactsPage: FC<{}> = () => {
  const { t } = useTranslation()

  usePage(pageService.getPageWithId('game-update-impacts'), 'Visited Game Update Impacts')
  useDocumentTitle(t('sidebar:game-update-impacts'))

  const { currentMarketIso } = useCurrentMarket()
  const isFeatureAvailableForMarket = revenueIsAvailableForMarket(currentMarketIso)
  const hasAccessRights = useGameUpdateImpactsAccessCheck()
  const [versionTags, setVersionTags] = useState<VersionTag[]>([])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const initialFilters: UpdateImpactRowFilters = useMemo(
    () => ({
      versionTags: [...convertVersionTags([...VersionTagFeature]), ...versionTags].filter((tag) => tag.name !== ''),
    }),
    [versionTags]
  )

  const onFilterChange = useCallback((filters: UpdateImpactRowFilters) => {
    if (filters.versionTags) {
      setVersionTags(filters.versionTags)
    }
  }, [])

  return isFeatureAvailableForMarket ? (
    <>
      <Grid container columnSpacing={3} rowSpacing={1} wrap="wrap">
        {!hasAccessRights && (
          <Grid item flexBasis="100%">
            <LimitedFunctionalityBanner lockedFeatureId={LockedFeatureId.GameUpdateImpacts} />
          </Grid>
        )}
        <Grid container item justifyContent="space-between" wrap="nowrap">
          <Grid item xs={9}>
            <Typography variant="body1" mb={1} mt={1}>
              {t('common:game_update_impacts_description')}
            </Typography>
            <Typography variant="body1" mb={3} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <InfoIcon fontSize="small" color="error" />
              {t('common:game_update_impacts_live_events_note')}
            </Typography>
          </Grid>
          <Grid item>
            <ShareUrlButton />
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="h2" mb={3} mt={1}>
        {t('common:filter_game_updates')}
      </Typography>
      <UpdateImpactsTableContainer showSubgenrePicker showTimeSinceUpdatedSelector initialFilters={initialFilters} onFilterChange={onFilterChange} />
    </>
  ) : (
    <Typography variant="body1">{t('common:update_impacts_not_available_text')}</Typography>
  )
}
