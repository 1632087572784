import { CSSProperties, ReactNode, useRef } from 'react'

import { DragIndicator } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'

import type { GRTableColumn } from '../GRTable'

type StaticRowProps<RowType, CustomPropsType, ColumnIdType = void> = {
  row: RowType
  rowIndex: number
  columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  getCellValue: ({ ...args }: { row: RowType; col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>; rowIndex: number }) => ReactNode
  style?: CSSProperties
}

const StaticRow = <RowType extends object, CustomPropsType extends object | undefined, ColumnIdType extends string | void>({
  row,
  rowIndex,
  columns,
  getCellValue,
  style,
}: StaticRowProps<RowType, CustomPropsType, ColumnIdType>) => {
  const rowRef = useRef<HTMLTableRowElement>(null)

  const dragHandleColumn = {
    headerCellProps: { sx: { width: 60 } },
    accessor: () => {
      return (
        <IconButton color="primary" style={{ cursor: 'grab' }}>
          <DragIndicator />
        </IconButton>
      )
    },
  }

  const customColumns = [dragHandleColumn, ...columns] as GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  return (
    <TableRow
      style={{
        height: `${rowRef!.current?.offsetHeight}px`,
        position: 'absolute',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        maxWidth: '100%',
        opacity: 0.8,
        ...style,
      }}
    >
      {customColumns.map((column, columnIndex) => {
        return (
          <TableCell style={{ width: column.width, minWidth: column.width, maxWidth: column.width }} key={columnIndex}>
            {getCellValue({ row, col: column, rowIndex: rowIndex })}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default StaticRow
