import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Button, CircularProgress, Grid } from '@mui/material'

import { useCurrentUserLanguage } from '../../features/account/hooks/useCurrentUserLanguage'
import { Game, GameCardList, useFeaturedGames, useFollowedGames } from '../../features/game'
import { TrackedGamesPermissionMap } from '../../features/game-search/types/GameSearchDialogConfig'
import { useGameAndAnalysis } from '../../features/game/hooks/gameHooks'
import { useTrackedGames } from '../../features/live-events/hooks/useTrackedGames'
import { useCurrentMarket } from '../../features/markets'
import { NewsCard, useUnifiedNewsEntry } from '../../features/news'
import { useMobileSize } from '../../features/responsiveness/hooks/responsivenessHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'
import { INCLUDE } from '../DailyInsightsPage/DailyInsightsPage'
import './SingleUnifiedNewsEntryPage.scss'

const SingleUnifiedNewsEntryPage: React.FC = () => {
  const { t } = useTranslation()
  const language = useCurrentUserLanguage()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const navigate = useNavigate()
  usePage(PageService.getPageWithId('news-card'), 'Visited Single Unified News Entry View')
  const allTrackedGames = useTrackedGames({})

  const { id: newsCardId } = useParams<{ id: string }>()
  const { unifiedNewsEntry, unifiedNewsEntryLoading, unifiedNewsEntryError } = useUnifiedNewsEntry(mainMarketIso, newsCardId || '', language)
  useDocumentTitle(t('newsfeed:newscard'))
  const { gameAndAnalysis } = useGameAndAnalysis(
    unifiedNewsEntry?.feedEntry?.type === 'editorial' ? '' : unifiedNewsEntry?.feedEntry?.gameId ?? '',
    mainMarketIso
  )

  const mobileSize = useMobileSize('sm')
  const featuredGames = useFeaturedGames(mainMarketIso, INCLUDE)
  const followedGames = useFollowedGames(mainMarketIso, INCLUDE)

  const gameSearchConfig = useMemo(() => {
    return {
      trackedGamesPermissionMap: allTrackedGames.data
        ? allTrackedGames.data.reduce((acc, trackedGame) => {
            return { ...acc, [trackedGame.game.id as string]: trackedGame.permission } as TrackedGamesPermissionMap
          }, {} as TrackedGamesPermissionMap)
        : ({} as TrackedGamesPermissionMap),
    }
  }, [allTrackedGames.data])

  const hasGamePermission = useCallback(
    (game: Game) => {
      if (unifiedNewsEntry?.feedEntry?.baseType !== 'live_event') {
        return true
      }
      return gameSearchConfig.trackedGamesPermissionMap?.[game.id] ?? false
    },
    [gameSearchConfig.trackedGamesPermissionMap, unifiedNewsEntry]
  )

  if (gameAndAnalysis && !hasGamePermission(gameAndAnalysis.game)) {
    navigate('/')
    return null
  }

  if ((!gameAndAnalysis && unifiedNewsEntry?.feedEntry?.type !== 'editorial') || unifiedNewsEntryLoading || unifiedNewsEntryError) {
    return (
      <div className="text-center top-margin">
        <CircularProgress color="primary" />
      </div>
    )
  }

  return (
    <Grid container spacing={{ xs: 0, md: 1 }}>
      <Grid item xs>
        <div className="SingleUnifiedNewsEntryPage">
          {unifiedNewsEntryLoading && (
            <div className="text-center top-margin">
              <CircularProgress color="primary" />
            </div>
          )}
          {!unifiedNewsEntryLoading && unifiedNewsEntry ? (
            <Grid container>
              <Grid item xs>
                <NewsCard newsEntry={unifiedNewsEntry} />

                <div className="text-center">
                  <Link to="/daily-insights">
                    <Button variant="contained" color="primary">
                      {t('newsfeed:show_daily_insights')}
                    </Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          ) : null}
          {unifiedNewsEntryError && <div className="text-center">{t('common:general_error_message')}</div>}
        </div>
      </Grid>
      <Grid item>
        {!mobileSize && (
          <div className="DailyInsightsPage__game-card-lists">
            <GameCardList title={t('newsfeed:featured_games')} games={featuredGames} />
            <GameCardList title={t('newsfeed:heading_followed_games')} games={followedGames} displayLastSeenBadge={true} showUnfollowButton={true} />
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default SingleUnifiedNewsEntryPage
