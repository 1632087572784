import classNames from 'classnames'
import { CSSProperties, ReactNode, memo } from 'react'
import { VirtualItem } from 'react-virtual'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DragIndicator } from '@mui/icons-material'
import { TableRow, SxProps, IconButton } from '@mui/material'

import { BodyCell } from '../BodyCell/BodyCell'
import type { GRTableColumn } from '../GRTable'
import { getChildColumns, isLastVisibleColumnOfParent, findParentColumn, stickyColumnProps } from '../helpers/tableHelpers'
import styles from './SortableRow.module.scss'

type SortableRowProps<RowType, CustomPropsType, ColumnIdType = void> = {
  row: RowType
  id: number
  reorder: boolean
  virtualRow: VirtualItem & { style?: CSSProperties }
  columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  parentColumns?: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  getCellValue: ({ ...args }: { row: RowType; col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>; rowIndex: number }) => ReactNode
  getColumnHiddenValue: (col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => boolean
  hoverable?: boolean
  striped?: boolean
  style?: CSSProperties
  className?: string
  gridlines?: boolean
}

const RecursiveSortableVirtualRow = <RowType extends object, CustomPropsType extends object | undefined, ColumnIdType extends string | void>({
  row,
  id,
  reorder,
  virtualRow,
  columns,
  parentColumns = [],
  getCellValue,
  getColumnHiddenValue,
  striped,
  style,
  hoverable,
  className,
  gridlines,
}: SortableRowProps<RowType, CustomPropsType, ColumnIdType>) => {
  const childColumns = getChildColumns(columns)
  const rowClassName = classNames(className, { [styles.even]: striped && virtualRow.index % 2 === 0 })

  const { attributes, listeners, transform, setNodeRef, transition, isDragging } = useSortable({ id: id + 1 })
  const dragHandleColumn = {
    width: 60,
    accessor: () => {
      return (
        <IconButton color="primary" {...listeners} {...attributes} style={{ cursor: 'grab' }} disabled={!reorder}>
          <DragIndicator />
        </IconButton>
      )
    },
  }

  const customStyles: CSSProperties = {
    transform: isDragging ? 'unset' : CSS.Translate.toString(transform),
    transition: transition,
    top: virtualRow.start,
    zIndex: isDragging ? 100 : 0,
    position: 'absolute',
    visibility: isDragging ? 'hidden' : 'visible',
    ...style,
  }

  const customColumns = [dragHandleColumn, ...columns] as GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]

  return childColumns.length > 0 ? (
    <RecursiveSortableVirtualRow
      row={row}
      id={id}
      reorder={reorder}
      virtualRow={virtualRow}
      columns={childColumns}
      className={rowClassName}
      parentColumns={columns}
      getCellValue={getCellValue}
      getColumnHiddenValue={getColumnHiddenValue}
      striped={striped}
      style={style}
      hoverable={hoverable}
    />
  ) : (
    <TableRow
      ref={(node) => {
        setNodeRef(node)
        virtualRow.measureRef(node)
      }}
      style={{
        ...customStyles,
      }}
      hover={hoverable}
      className={rowClassName}
    >
      {customColumns.map((column, columnIndex) => {
        const rowIndex = virtualRow.index
        const isGrouping = isLastVisibleColumnOfParent(parentColumns, column, getColumnHiddenValue) || childColumns.length > 0
        const parentColumn = findParentColumn(parentColumns, column)
        const isStickyColumn = column.sticky || parentColumn?.sticky
        const cellProps = isStickyColumn ? { ...column.cellProps, sx: { ...column.cellProps?.sx, ...stickyColumnProps.sx } as SxProps } : column.cellProps
        const isHidden = (parentColumn && getColumnHiddenValue(parentColumn)) || getColumnHiddenValue(column)
        return isHidden ? null : (
          <BodyCell
            key={columnIndex}
            columnIndex={columnIndex}
            cellProps={cellProps}
            grouping={isGrouping || gridlines}
            style={{ width: column.width, minWidth: column.width, maxWidth: column.width, paddingRight: isStickyColumn ? 24 : undefined }}
          >
            {getCellValue({ row, col: column, rowIndex })}
          </BodyCell>
        )
      })}
    </TableRow>
  )
}

export const SortableRow = memo(RecursiveSortableVirtualRow) as typeof RecursiveSortableVirtualRow
